export const wiczlino2 = [
  {
    "0": "GWII-GHC-10.1",
    "1": "1",
    "2": "19",
    "3": "140,20m2",
    "5": "Zapytaj",
    "4": "454,00",
    "6": "południe, zachód, wschód",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "https://willbud.pl/_pliki/GWII-GHC-10.1.pdf",
    "12": "large",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour: "",
  },
  {
    "0": "GWII-GHC-10.2",
    "1": "1",
    "3": "140,20m2",
    "2": "20",
    "5": "Zapytaj",
    "4": "434,00",
    "6": "południe, zachód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "https://willbud.pl/_pliki/GWII-GHC-10.2.pdf",
    "12": "small",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour: "",
  },
  {
    "0": "GWII-GHC-11.1",
    "1": "1",
    "2": "21",
    "3": "140,20m2",
    "5": "Zapytaj",
    "4": "432,00",
    "6": "zachód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "https://willbud.pl/_pliki/GWII-GHC-11.1.pdf",
    "12": "medium",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour: "",
  },
  {
    "0": "GWII-GHC-11.2",
    "1": "1",
    "2": "22",
    "3": "140,20m2",
    "5": "Zapytaj",
    "4": "439,00",
    "6": "zachód, wschód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "https://willbud.pl/_pliki/GWII-GHC-11.2.pdf",
    "12": "small",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour: "",
  },
  {
    "0": "GWII-GHC-12.1",
    "1": "1",
    "2": "23",
    "3": "140,20m2",
    "5": "Zapytaj",
    "4": "413,00",
    "6": "południe, wschód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "https://willbud.pl/_pliki/GWII-GHC-12.1.pdf",
    "12": "medium",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour: "",
  },
  {
    "0": "GWII-GHC-12.2",
    "1": "1",
    "2": "24",
    "3": "140,20m2",
    "5": "Zapytaj",
    "4": "424,00",
    "6": "południe, zachód, wschód",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "https://willbud.pl/_pliki/GWII-GHC-12.2.pdf",
    "12": "large",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour: "",
  },
  {
    "0": "GWII-GHC-13.1",
    "1": "1",
    "2": "25",
    "3": "140,20m2",
    "5": "Zapytaj",
    "4": "433,00",
    "6": "południe, zachód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "https://willbud.pl/_pliki/GWII-GHC-13.1.pdf",
    "12": "medium",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour: "",
  },
  {
    "0": "GWII-GHC-13.2",
    "1": "1",
    "2": "26",
    "3": "140,20m2",
    "5": "Zapytaj",
    "4": "427,00",
    "6": "zachód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "https://willbud.pl/_pliki/GWII-GHC-13.2.pdf",
    "12": "medium",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour: "",
  },
  {
    "0": "GWII-GHC-14.1",
    "1": "2",
    "2": "27",
    "3": "140,20m2",
    "5": "Zapytaj",
    "4": "416,00",
    "6": "południe, zachód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "https://willbud.pl/_pliki/GHC-14.1.pdf",
    "12": "medium",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour: "",
  },
  {
    "0": "GWII-GHC-14.2",
    "1": "2",
    "2": "28",
    "3": "139m2",
    "5": "Zapytaj",
    "4": "415,00",
    "6": "południe, zachód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "/assets/files/ogw2/GHC-14.2.pdf",
    "12": "medium",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour:
      "https://tours.3destate.pl/Process/e04fc5b7-1dd9-42c7-aaaf-b115996a6196.3d",
  },
  {
    "0": "GWII-GHC-15.1",
    "1": "1",
    "2": "29",
    "3": "140,20m2",
    "5": "Zapytaj",
    "4": "427,00",
    "6": "zachód, wschód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "https://willbud.pl/_pliki/GWII-GHC-15.1.pdf",
    "12": "small",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour: "",
  },
  {
    "0": "GWII-GHC-15.2",
    "1": "1",
    "2": "30",
    "3": "139m2",
    "5": "Zapytaj",
    "4": "427,00",
    "6": "południe, wschód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "https://willbud.pl/_pliki/GWII-GHC-15.2.pdf",
    "12": "medium",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour:
      "https://tours.3destate.pl/Process/e04fc5b7-1dd9-42c7-aaaf-b115996a6196.3d",
  },
  {
    "0": "GWII-GHP-16.1",
    "1": "1",
    "2": "31",
    "3": "164,44m2",
    "5": "Zapytaj",
    "4": "441,00",
    "6": "południe, wschód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "/assets/files/ogw2/GHP-16.1.pdf",
    "12": "medium",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour:
      "https://tours.3destate.pl/Process/9349857e-9203-40b7-b4f4-34d6f35b09f4.3d",
  },
  {
    "0": "GWII-GHP-16.2",
    "1": "1",
    "2": "32",
    "3": "158,40m2",
    "5": "Zapytaj",
    "4": "441,00",
    "6": "południe, wschód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "https://willbud.pl/_pliki/GHP-16.2.pdf",
    "12": "medium",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour: "",
  },
  {
    "0": "GWII-GHP-17.1",
    "1": "2",
    "2": "33",
    "3": "158,40m2",
    "5": "Zapytaj",
    "4": "433,00",
    "6": "południe, zachód, wschód",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "https://willbud.pl/_pliki/GWII-GHP-17.1.pdf",
    "12": "large",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour: "",
  },
  {
    "0": "GWII-GHP-17.2",
    "1": "2",
    "2": "34",
    "3": "158,40m2",
    "5": "Zapytaj",
    "4": "470,00",
    "6": "południe, zachód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "https://willbud.pl/_pliki/GWII-GHP-17.2.pdf",
    "12": "medium",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour: "",
  },
  {
    "0": "GWII-GHP-18.1",
    "1": "2",
    "2": "35",
    "3": "158,40m2",
    "5": "Zapytaj",
    "4": "463,00",
    "6": "południe, zachód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "https://willbud.pl/_pliki/GHP-18.1.pdf",
    "12": "medium",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour: "",
  },
  {
    "0": "GWII-GHP-18.2",
    "1": "2",
    "2": "36",
    "3": "164,44m2",
    "5": "Zapytaj",
    "4": "425,00",
    "6": "południe, zachód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "/assets/files/ogw2/GHP-18.2.pdf",
    "12": "medium",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour:
      "https://tours.3destate.pl/Process/1b192481-f394-45e8-a144-949f516b31cc.3d",
  },
  {
    "0": "GWII-GHC-19.1",
    "1": "1",
    "2": "37",
    "3": "139m2",
    "5": "Zapytaj",
    "4": "434,00",
    "6": "południe, wschód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "/assets/files/ogw2/GHC-19.1.pdf",
    "12": "medium",
    "13": "",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour:
      "https://tours.3destate.pl/Process/dd3d78b3-64bf-4e6f-b367-6d9504231211.3d",
  },
  {
    "0": "GWII-GHC-19.2",
    "1": "1",
    "2": "38",
    "3": "139m2",
    "5": "Zapytaj",
    "4": "422,00",
    "6": "południe, wschód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "/assets/files/ogw2/GHC-19.2.pdf",
    "12": "medium",
    "13": "",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    price: "857 159 zł",
    tour:
      "https://tours.3destate.pl/Process/e04fc5b7-1dd9-42c7-aaaf-b115996a6196.3d",
  },
  {
    "0": "GWII-GHP-20.1",
    "1": "2",
    "2": "39",
    "3": "158,40m2",
    "5": "Zapytaj",
    "4": "440,00",
    "6": "południe, zachód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "https://willbud.pl/_pliki/GHP-20.1.pdf",
    "12": "medium",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour: "",
  },
  {
    "0": "GWII-GHP-20.2",
    "1": "2",
    "2": "40",
    "3": "158,40m2",
    "5": "Zapytaj",
    "4": "450,00",
    "6": "południe, zachód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "https://willbud.pl/_pliki/GHP-20.2.pdf",
    "12": "medium",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour: "",
  },
  {
    "0": "GWII-GHP-21.1",
    "1": "1",
    "2": "41",
    "3": "160,5m2",
    "5": "Zapytaj",
    "4": "441,00",
    "6": "południe, wschód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "/assets/files/ogw2/GHP-21.1.pdf",
    "12": "medium",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour:
      "https://tours.3destate.pl/Process/6b98acfd-5360-4014-aabf-cabc72f8c893.3d",
  },
  {
    "0": "GWII-GHP-21.2",
    "1": "1",
    "2": "42",
    "3": "160,5m2",
    "5": "Zapytaj",
    "4": "441,00",
    "6": "południe, wschód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "/assets/files/ogw2/GHP-21.2.pdf",
    "12": "medium",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour:
      "https://tours.3destate.pl/Process/9ad66f1f-d26c-43f3-8098-6c984ccfc333.3d",
  },
  {
    "0": "GWII-GHP-22.1",
    "1": "2",
    "2": "43",
    "3": "160,50m2",
    "5": "Zapytaj",
    "4": "460,00",
    "6": "południe, zachód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "https://willbud.pl/_pliki/GHP-22.1.pdf",
    "12": "medium",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour:
      "https://tours.3destate.pl/Process/6b98acfd-5360-4014-aabf-cabc72f8c893.3d",
  },
  {
    "0": "GWII-GHP-22.2",
    "1": "2",
    "2": "44",
    "3": "158,40m2",
    "5": "Zapytaj",
    "4": "431,00",
    "6": "południe, zachód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "https://willbud.pl/_pliki/GHP-22.2.pdf",
    "12": "medium",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour: "",
  },
  {
    "0": "GWII-GHP-23.1",
    "1": "2",
    "2": "45",
    "3": "160,5m2",
    "5": "Zapytaj",
    "4": "425,00",
    "6": "południe, zachód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "/assets/files/ogw2/GHP-23.1.pdf",
    "12": "medium",
    "13": "true",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour:
      "https://tours.3destate.pl/Process/6b98acfd-5360-4014-aabf-cabc72f8c893.3d",
  },
  {
    "0": "GWII-GHP-23.2",
    "1": "2",
    "2": "46",
    "3": "160,5m2",
    "5": "Zapytaj",
    "4": "463,00",
    "6": "południe, zachód, północ",
    "7": "Gotowe",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "/assets/files/ogw2/GHP-23.2.pdf",
    "12": "medium",
    "13": "",
    "14": "https://willbud.pl/new/logowiczlino.png",
    "15": "wiczlino2",
    tour:
      "https://tours.3destate.pl/Process/9ad66f1f-d26c-43f3-8098-6c984ccfc333.3d",
  },
  {
    "0": "GWII-GHC-5.1",
    "1": "2",
    "2": "",
    "3": "139m2",
    "5": "Zapytaj",
    "4": "417,00",
    "6": "południe, zachód, północ",
    "7": "II kwartał 2025",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "/assets/files/ogw2/GHC-5.1-Umowa_rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "",
    "15": "wiczlino2",
    tour:
      "https://tours.3destate.pl/Process/e04fc5b7-1dd9-42c7-aaaf-b115996a6196.3d",
  },
  {
    "0": "GWII-GHC-5.2",
    "1": "2",
    "2": "",
    "3": "139m2",
    "5": "Zapytaj",
    "4": "411,00",
    "6": "wschód, zachód, północ",
    "7": "II kwartał 2025",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "/assets/files/ogw2/GHC-5.2-Umowa_rezerwacyjna.pdf",
    "12": "medium",
    "13": "true",
    "14": "",
    "15": "wiczlino2",
    tour:
      "https://tours.3destate.pl/Process/e04fc5b7-1dd9-42c7-aaaf-b115996a6196.3d",
  },
  {
    "0": "GWII-GHC-7.1",
    "1": "2",
    "2": "",
    "3": "139m2",
    "5": "Zapytaj",
    "4": "417,00",
    "6": "wschód, zachód, północ",
    "7": "II kwartał 2025",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "/assets/files/ogw2/GHC-7.1-Umowa_rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "",
    "15": "wiczlino2",
    tour:
      "https://tours.3destate.pl/Process/e04fc5b7-1dd9-42c7-aaaf-b115996a6196.3d",
  },
  {
    "0": "GWII-GHC-7.2",
    "1": "2",
    "2": "",
    "3": "139m2",
    "5": "Zapytaj",
    "4": "417,00",
    "6": "południe, zachód, północ",
    "7": "II kwartał 2025",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "/assets/files/ogw2/GHC-7.2-Umowa_rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "",
    "15": "wiczlino2",
    tour:
      "https://tours.3destate.pl/Process/e04fc5b7-1dd9-42c7-aaaf-b115996a6196.3d",
  },
  {
    "0": "GWII-GHC-9.1",
    "1": "2",
    "2": "",
    "3": "139m2",
    "5": "Zapytaj",
    "4": "436,00",
    "6": "wschód, zachód, północ",
    "7": "II kwartał 2025",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "/assets/files/ogw2/GHC-9.1-Umowa_rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "",
    "15": "wiczlino2",
    tour:
      "https://tours.3destate.pl/Process/e04fc5b7-1dd9-42c7-aaaf-b115996a6196.3d",
  },
  {
    "0": "GWII-GHC-9.2",
    "1": "2",
    "2": "",
    "3": "139m2",
    "5": "Zapytaj",
    "4": "396,00",
    "6": "południe, zachód, wschód",
    "7": "II kwartał 2025",
    "8": "Sprawdź",
    "9": "Zapytaj",
    "10": "Zobacz",
    "11": "/assets/files/ogw2/GHC-9.2-Umowa_rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "",
    "15": "wiczlino2",
    tour:
      "https://tours.3destate.pl/Process/e04fc5b7-1dd9-42c7-aaaf-b115996a6196.3d",
  },
]
